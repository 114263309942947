<template>
  <Container>
    <div class="learning-overview">
      <component
        :is="componentName(element, components)"
        v-for="(element, index) in page.elements"
        :key="element.id"
        :element="element"
        :count="index"
      />
      <div class="learning-overview-fixed">
        <OverviewSidebar :element="overviewSidebar" />
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
  import { defineAsyncComponent } from "vue"
  import { useNotificationStore } from "~/pinia/notifications"
  import OverviewSidebar from "~/alchemy/learningOverview/overview_sidebar_list.vue"
  const { componentName } = useAlchemy()

  const props = defineProps<{
    page: AlchemyPage
  }>()

  const components = {
    article_category: defineAsyncComponent(
      () => import("~/alchemy/learningOverview/article_category.vue"),
    ),
    learning_article_highlight: defineAsyncComponent(
      () => import("~/alchemy/learningOverview/learning_article_highlight.vue"),
    ),
    learning_image: defineAsyncComponent(
      () => import("~/alchemy/learningOverview/learning_image.vue"),
    ),
    learning_product: defineAsyncComponent(
      () => import("~/alchemy/learningOverview/learning_product.vue"),
    ),
  }

  const overviewSidebar = props.page.fixedElements.find(
    (el) => el.name === "overview_sidebar_list",
  )

  useHead(usePageInfo(props.page))
  const notificationStore = useNotificationStore()
  onMounted(() => {
    notificationStore.announce(props.page.title)
  })
</script>

<style lang="scss" scoped>
  .learning-overview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: min-content;
    grid-gap: $space-l;
    @include viewport("md", "lg") {
      *:first-child {
        grid-column: 1/3;
      }
    }
    @include viewport("md") {
      grid-column-gap: $base-spacing * 8;
    }
    @include viewport("sm", "m") {
      grid-gap: $space-m;
      grid-template-columns: repeat(2, 1fr);
    }
    @include viewport("sm") {
      grid-gap: $space-s;
    }

    @include viewport("mini") {
      grid-template-rows: auto;

      grid-gap: $space-m;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }
  }
  .learning-overview-fixed {
    grid-row: 1;
    @include viewport("md", "lg") {
      grid-column: 3;
    }
    @include viewport("sm", "m") {
      grid-column: 2;
    }
    @include viewport("mini") {
      grid-column: initial;
      grid-row: 2;
    }
  }
</style>
