<template>
  <div class="overview-sidebar-wrap">
    <h2 class="overview-sidebar-headline">
      {{ headline }}
    </h2>
    <div class="overview-sidebar">
      <component
        :is="componentName(element)"
        v-for="element in nestedElements"
        :key="element.id"
        :element="element"
        :type="element.name === 'learning_sidebar_product' && 'product'"
      />
    </div>
  </div>
</template>

<script>
  import LearningSidebarProduct from "./learning_sidebar_product.vue"
  import LearningSidebarArticleTeaser from "./learning_sidebar_article_teaser.vue"
  import { AlchemyElement } from "~/mixins/alchemyVue"
  import Headline from "~/components/Headline"

  export default {
    components: {
      Headline,
      learning_sidebar_product: LearningSidebarProduct,
      learning_sidebar_article_teaser: LearningSidebarArticleTeaser,
    },
    mixins: [AlchemyElement],
    computed: {
      headline() {
        return this.getValue("headline")
      },
      nestedElements() {
        return this.element.nestedElements
      },
    },
  }
</script>

<style lang="scss" scoped>
  .overview-sidebar {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: $base-spacing * 4;
    padding-bottom: $space-m;
  }

  .overview-sidebar-headline {
    height: $base-spacing * 8;
    font-weight: bold;
    margin: 0;
    margin-bottom: $space-s;
  }
</style>
