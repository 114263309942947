<template>
  <Article
    :title="title"
    :src="productImage"
    legend="product"
    :alt="`Product image for ${productName}`"
    :href="productUrl"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],

    computed: {
      title() {
        return this.getValue("title")
      },
      description() {
        return this.getValue("description")
      },
      productUrl() {
        return this.product.productUrl || ""
      },
      type() {
        return this.getValue("type")
      },
      productImage() {
        return this.product.productImageUrl
      },
      productName() {
        return this.product.productName
      },
      product() {
        return this.getIngredient("product") || {}
      },
    },
  }
</script>

<style lang="scss" scoped>
  .learning-sidebar-product-wrap {
    display: flex;
    justify-content: space-between;
    grid-gap: $space-s;
  }
  .learning-sidebar-title {
    font-weight: bold;
  }
  .learning-sidebar-product-image {
    width: 100px;
    border-radius: $border-radius;
    height: 80px;
    object-fit: cover;
  }
</style>
