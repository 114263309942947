<template>
  <Article
    :title="title"
    :src="image"
    :legend="type"
    :href="articleLink"
    :alt="imageAltText"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      title() {
        return this.getValue("title")
      },
      image() {
        return this.getValue("image")
      },
      imageAltText() {
        return this.getIngredient("image")?.altText
      },
      articleLink() {
        return this.getValue("article_link") || ""
      },
      type() {
        return this.getValue("type")
      },
    },
  }
</script>
